.card,
.alert {
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.1);
  border: 0;
  overflow: hidden;

  .card-header {
    border-radius: 8px;
  }
}

.card-highlight {
  box-shadow: 0 8px 32px 0 rgba(47, 51, 51, 0.03);
  -webkit-transition: box-shadow 0.25s;
  transition: box-shadow 0.25s;
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  &:hover {
    box-shadow: 0 32px 32px 0 rgba(47, 51, 51, 0.02), 0 8px 32px 0 rgba(47, 51, 51, 0.1);

    // Jump out transition
    @media only screen and (min-width: 575.98px) {
      transform: scale(1.05);
      z-index: 99;
    }
  }

  &:active {
    box-shadow: 0 32px 32px 0 rgba(47, 51, 51, 0.02), 0 8px 32px 0 rgba(47, 51, 51, 0.1);

    // Jump out transition
    @media only screen and (min-width: 575.98px) {
      transform: scale(1.01);
      z-index: 99;
    }
  }
}
