.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 100%;
  background-color: #fff;
  font-weight: 600;
  font-size: 16px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 2px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: rgb(218, 229, 194);
}

.search-bar {
  background: #fff;
  border-radius: 0 0 5px 5px;
  padding: 10px;

  .form-control {
    padding: 0.375rem 0.75rem;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #ccc;

    &:focus {
      box-shadow: none;
    }

    input {
      font-size: 1rem;
      font-weight: 500;
      border: 0;
    }
  }

  .react-autosuggest__input {
    width: 90%;
    margin-right: 10%;
  }

  .react-datepicker-input {
    border-bottom: 1pt solid #ced4da;
  }

  .icon-close {
    cursor: pointer;
  }
}

.search-bar.in-header {
  background-color: transparent;
}

.date-picker-input {
  border: 0;
  flex: 1 1 0%;
  flex-direction: row;
  color: rgb(73, 80, 87) !important;
  border-bottom: 1pt solid #ccc;
}

.react-autosuggest__suggestions-container--open {
  border-left: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  border-radius: 0.25rem;
}
