@font-face {
  font-family: 'SimpleLineIcons';
  font-style: normal;
  font-weight: 400;
  src: url('~simple-line-icons/fonts/Simple-Line-Icons.woff2') format('truetype');
}

html,
body,
#root {
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-family-base;
  color: #283646;
  font-size: 0.95rem;
  line-height: 1.65rem;
  background-color: rgb(250, 250, 250);
}

body:not(.modal-open) {
  overflow: scroll !important;
}

.section {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 40px;
  padding-bottom: 40px;

  &.section-grey {
    background: rgb(245, 242, 240);
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.footer {
  background-color: #f7f7f7;
  border-top: 1pt solid #ddd;

  a.nav-link {
    color: $success;
    font-size: 0.9rem;
    text-decoration: none !important;
    padding: 0.2rem 1rem;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}

a {
  color: $success;
  text-decoration: none;
}

.btn-link {
  text-decoration: none;
}

.fw-medium {
  font-weight: 600;
}

.text-orange {
  color: rgb(243, 96, 74);
}
.text-green {
  color: rgb(124, 163, 36);
}

hr {
  max-width: 50px;
  border-color: #d05427;
  border-width: 2px;

  &.light {
    border-color: #fff;
  }

  &.dark {
    border-color: #283646;
  }

  &.full {
    max-width: 100%;
    border-color: rgb(124, 163, 36);
    border-width: 1px;
  }

  &.grey {
    border-color: #16403c;
  }
}

p {
  margin-bottom: 1rem;
  line-height: 1.65rem;
}

.font-weight-medium {
  font-weight: 600;
}

.badge {
  white-space: normal;
  text-align: left;
  line-height: 14px;
}
@include media-breakpoint-only(xs) {
  .form-group {
    margin-bottom: 0;
  }

  .form-group:last-child {
    margin-bottom: 1em;
  }

  .form-group.mtop {
    margin-top: 1em;
  }
}

label {
  margin-bottom: 0.2rem;
  margin-top: 1rem;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

input::placeholder {
  color: #ccc;
  font-size: 0.9em;
}

.form-control:focus {
  border-color: #495057;
  box-shadow: none;
}

input.invalid {
  border-color: red !important;
}

.fade-appear,
.fade-enter {
  opacity: 0;
}

.fade-appear-active,
.fade-enter-active {
  transition: opacity 0.3s linear;
  opacity: 1;
}

.fade-exit {
  transition: opacity 0.2s linear;
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

@include media-breakpoint-up(md) {
  label {
    margin-top: 0;
  }

  .container {
    min-width: 700px;
  }
}

@media (min-width: 1650px) {
  .booking-page-layout.container {
    max-width: 1600px;
  }
}

@include media-breakpoint-only(xs) {
  .popular-destinations {
    .row .ts-item:last-child {
      display: none;
    }
  }
}
@include media-breakpoint-only(lg) {
  .popular-destinations {
    .row .ts-item:last-child {
      display: none;
    }
  }
}
@include media-breakpoint-only(xl) {
  .popular-destinations {
    img {
      height: 230px;
    }
  }
}

.line-through {
  text-decoration: line-through;
}

.price-option-help {
  min-width: 40px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px;
  border: 2px solid rgba(124, 163, 36, 0.898);
  border-radius: 5px;
  background: white;
}

.price-option-help.multiple {
  background: rgba(240, 171, 41, 0.188);
}

.availability-list {
  margin-left: 10px;
  flex-grow: 1;
}

.partial-border {
  position: relative;
  z-index: 1;
}

.partial-border:before {
  content: '';
  border-top: 2px solid #7ca324;
  position: absolute;
  width: 80%;
  left: 10%;
  top: 0;
}

.partial-border.slim:before {
  content: '';
  border-top: 2px solid #7ca324;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 0;
}

.shopping-cart-box {
  margin-top: 20px;
  padding: 20px 30px 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid rgba(0, 0, 0, 0.141);
  border-radius: 1px;
  opacity: 1;
}

.map-window {
  height: 300px;
}

.large-map {
  height: 600px;
  margin-bottom: 1rem;
}

.map-button {
  background-color: rgba(255, 255, 255, 0.439);

  &:hover {
    background-color: 'success';
  }
}

.text-600 {
  text-align: center;
  font-weight: 600;
  display: inline-block;
}

.border-bottom-green {
  border-bottom: 1px solid #7ca324;
}

.green-line {
  border-bottom: 1px solid #7ca324;
  margin: 0;
}

.course-img {
  width: 100%;
  object-fit: cover;
  height: 300px;

  @include media-breakpoint-up(xs) {
    height: 220px;
  }

  @include media-breakpoint-up(sm) {
    height: 140px;
  }

  @include media-breakpoint-up(xl) {
    height: 180px;
  }

  @media only screen and (min-width: 1800px) {
    height: 300px;
  }
}

.carousel-image {
  display: block;
  min-width: 0;

  &:hover {
    opacity: 0.9;
  }

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.carousel-image.simple {
  &:hover {
    opacity: 1;
  }
}

.carousel-image.cancelled {
  opacity: 0.4;
  &:hover {
    opacity: 0.4;
  }
}

.fslightbox-toolbar-button:nth-child(1) {
  display: none;
}

.orange-line {
  border-bottom: 1px solid rgba(240, 171, 41, 0.475);
  margin: 0 20px 5px 20px;
}

.top-line {
  border-top: 2px solid #7ca324;
}

.top-bottom-10 {
  margin: 10px 0;
}

.border-mockup-red {
  border: solid 1px red;
  margin: 2px;
}

.border-mockup-blue {
  border: solid 1px blue;
  margin: 2px;
}

.border-mockup-green {
  border: solid 1px green;
  margin: 2px;
}

.btn-no-padding {
  padding: 0;
}

.scale-hover {
  &:hover {
    transform: scale(1.1);
  }
}

.cancellation-protection {
  margin-top: 15px;
}

.orange-line-top-bottom {
  margin: 10px 0;
}

.rates-modal-button-group {
  padding-top: 15px;
}

.bigger-checkbox {
  margin-left: 2px !important;
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.vertical-middle-simple {
  text-align: center;
}

.add-players {
  border: 1px solid #999a9c;
  border-radius: 5px;
  padding: 20px;
}

.add-players.error {
  border: 2px solid #f3604a;
}

.add-players.error.shake {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.white-card-button {
  border: 1px solid rgba(47, 51, 51, 0.08);
  box-shadow: 0 4px 8px 0 rgba(47, 51, 51, 0.03);
  color: rgb(47, 51, 51);
  background-color: white;

  &:hover {
    border: 1px solid rgba(47, 51, 51, 0.4) !important;
    background-color: white !important;
    color: rgb(47, 51, 51) !important;
  }

  &:active {
    box-shadow: 0 32px 32px 0 rgba(47, 51, 51, 0.02), 0 8px 32px 0 rgba(47, 51, 51, 0.1) !important;
    border: 1px solid rgba(47, 51, 51, 0.4) !important;
    background-color: white !important;
    color: rgb(47, 51, 51) !important;
  }
}
