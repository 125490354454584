// Needs to be outside .my-page to prevent glitches
.left-menu {
  a.nav-link {
    padding: 0.6rem;
    border-radius: 5px;
    background-color: #fff;
    color: #000;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
    border: 0;
    box-shadow: 0 32px 32px 0 rgb(47 51 51 / 5%);
  }

  @media screen and (min-width: 576px) {
    a.nav-link {
      padding: 1rem;
    }
  }

  a.nav-link [class^="icon-"] {
    color: #000;
    padding-right: 15px;
  }

  a.nav-link.active {
    background-color: #7ca324;
    color: #fff;
  }

  a.nav-link.active [class^="icon-"] {
    color: #fff;
  }
}

.my-page,
.new-site {
  .hr-line {
    border-bottom: 1px solid rgba(47, 51, 51, 0.1);
    margin-bottom: 20px;
    padding-top: 20px;
  }
  button div.xx {
    display: inline-block;
    font-size: 12px !important;
    margin-left: 3px;
  }
  .modal-backdrop {
    background-color: #2c2c36;
    opacity: 0.8;
  }
  @media screen and (min-width: 576px) {
    .modal-header {
      padding: 0;
      border: 0;

      & .close {
        color: #fff;
        text-shadow: none;
        opacity: 1;
        font-size: 4rem;
        font-weight: normal;
        margin: -3.5rem -3.5rem -3.5rem auto;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .herobanner-container {
      padding-left: 125px;
      padding-right: 125px;
    }
  }
  .card {
    // box-shadow: 0 32px 32px 0 rgb(47 51 51 / 5%);
    border-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .card-header {
    background-color: #fff;
    padding-top: 30px;
    padding-left: 30px;
    border: 0;
    & h2 {
      font-weight: bold;
      border-bottom: 1px solid rgba(47, 51, 51, 0.1);
      padding-bottom: 14px;
    }
  }
  .card-body {
    padding-left: 30px;
  }
  .booking {
    cursor: pointer;
    padding: 12px 0;

    &:hover {
      background-color: rgb(124, 163, 36);
      color: white;
      border-radius: 5px;
    }
  }
  .date-time {
    margin-bottom: 0;
    line-height: 20px;
    font-weight: bold;
  }
  .golfclub {
    line-height: 20px;
  }
  .booking {
    .icon-user,
    .icon-people {
      display: inline-flex;
      font-size: 14px !important;
      margin-right: 5px;
    }
  }
  .canceled,
  .error {
    color: red;
  }

  .modal-dialog.small {
    max-width: 500px;
  }
  .booking-details {
    margin-top: 30px;
  }
  .booking-details ul {
    background-color: rgba(47, 51, 51, 0.05);
    padding: 8px 20px;
    list-style: none;
  }
  .booking-details li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.8;
    padding: 12px 0;
  }
  .booking-details li.double p {
    line-height: 1.2;
  }
  .booking-details li + li {
    border-top: 1px solid #2f33331a;
  }
  .booking-details li p {
    margin-bottom: 0;
  }
  .booking-details li > p:first-child {
    font-weight: 600;
  }
  .header-buttons {
    display: flex;
  }
  .header-buttons > button {
    color: #374555;
    border: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .header-buttons > button:not(:first-child) {
    margin-left: 2rem;
  }
  .header-buttons > button:hover,
  .header-buttons > button:active {
    color: #374555 !important;
    background-color: transparent !important;
  }
  .header-buttons > button.selected {
    font-weight: bold;
    border-bottom: 1px solid #7ca324;
  }
  .dropdown-menu {
    padding: 0;
  }
  .dropdown-menu button {
    padding: 10px;
  }
  .dropdown-menu.show {
    right: 0 !important;
    left: auto !important;
  }
  .dropdown-item:active {
    background-color: transparent !important;
  }
  .search-result button {
    padding: 1rem;
    border: 1pt solid #ced4da;
    margin-top: 5px;
    margin-bottom: 0;
    border-radius: 5px;
    width: 100%;
    text-align: left;
  }

  .friends {
    .icon-trash {
      cursor: pointer;
    }
  }

  .vouchers {
    td.tight {
      padding-top: 0;
    }
  }

  .player {
    color: #aaa;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-decoration: none;
    overflow: hidden;

    [class*="icon"] {
      margin-right: 10px;
    }

    &.selected {
      color: #7ca324;
      background-color: #e2e9d2;
      font-weight: 500;
    }
  }

  .how-to {
    h5 {
      color: #7ca324;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
}

.golfamore {
  // border: 10pt solid red;
  padding: 10px;

  .container {
    min-width: 100%;
  }
}

.feedback {
  input {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
  }
  .radio-buttons {
    display: inline-block;
    margin-right: 30px;
    margin-top: 10px;
  }
  .form-check-label {
    margin-right: 50px;
  }
  .form-check-label:last-child {
    margin-right: 10px;
  }
  @media (max-width: 576px) {
    .very-probably {
      display: block;
      text-align: right;
    }
  }
}
.help {
  .nav-item {
    padding: 6px 0;
  }
  .nav-item .nav-item {
    padding: 2px 0;
    padding-left: 20px;
  }
  .nav-item .active {
    font-weight: bold;
  }
}
