@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.wrapper {
  background: url('./images/background.png') no-repeat top center / cover;
}

.container {
  display: flex;
  flex-direction: row;
  padding: 60px 10px;
  gap: 40px;

  @include media-breakpoint-down(lg) {
    flex-direction: column-reverse;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;

  color: #1e1e1e;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 2;

  @include media-breakpoint-down(lg) {
    align-items: center;
    font-size: 16px;
    gap: 0;
  }
}

.title {
  color: #222529;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 30px;
}

.imagesWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.imagesWrapperItem {
  img {
    width: 100%;
    max-width: 250px;
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
