.theme {
  :global {
    @import 'DatePicker';
  }

  display: flex;
  justify-content: center;

  > :global(.react-datepicker) {
    display: inline-flex;
  }
}

.popper {
  background: #fff;
  box-shadow: inset 0 0 0 1px #dedede, 0 3px 5px #dedede;
  border-radius: 5px;
  padding: 20px;
}
