@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.title,
.description {
  color: #fff;
}

.title {
  font-size: 2.2rem;
  line-height: 3.2rem;
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.2);

  padding-bottom: 16px;

  @include media-breakpoint-up(md) {
    font-size: 3rem;
    line-height: 4.2rem;
    padding-bottom: 32px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 4.375rem;
    line-height: 6rem;
  }

  a {
    color: currentColor;
    text-decoration: underline;
  }
}

.description {
  font-size: 1.6rem;
  line-height: 2rem;

  @include media-breakpoint-up(md) {
    font-size: 1.875rem;
    line-height: 2.65rem;
  }
}

.placeholder,
.slider {
  background: transparent no-repeat center;
  background-size: cover;
}

.slider {
  transition: background ease 1s;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  min-height: 620px;
  padding-top: 20px;
  padding-bottom: 20px;

  @include media-breakpoint-up(md) {
    gap: 60px;
    min-height: 770px;
  }
}

.containerHeader,
.containerFooter {
  flex: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.containerHeader {
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

.containerFooter {
  justify-content: flex-start;
}
