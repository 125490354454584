@include media-breakpoint-up(sm) {
    .modal-dialog {
        max-width: 700px;
    }

    dl.companyinfo {
        width: 100%;
        overflow: hidden;
        padding: 0;
        margin: 0;
        margin-top: 25px;

        dt {
            float: left;
            width: 50%;
            padding: 0;
            margin: 0;
        }

        dd {
            float: left;
            width: 50%;
            padding: 0;
            margin: 0;
        }
    }
}
