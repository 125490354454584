h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat", "Open Sans", "Helvetica Neue", Arial, sans-serif;
    color: #283646;
    font-weight: 500;
    margin-bottom: 1rem;
}

h1 {
    font-size: 1.75rem;
    font-weight: 700;
}

h2 {
    font-size: 1.6rem;
}

h3 {
    font-size: 1.25rem;
}

h4 {
    font-size: 1.15rem;
}

h5 {
    font-size: 1.05rem;
}

.small {
    font-size: 0.95em;
}
