@import '~bootstrap/scss/variables';

.react-datepicker-popper {
  z-index: $zindex-sticky + ($zindex-fixed - $zindex-sticky) * 0.5 !important;
}

.react-datepicker {
  font-family: 'Montserrat', 'Open Sans', 'Helvetica Neue', Arial, sans-serif !important;

  &.react-datepicker_theme-ontee {
    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__navigation-icon::before {
      // border-color: rgb(40, 54, 70);
      border-color: #fff;
      margin-top: 3px;
    }

    .react-datepicker__header {
      background-color: rgb(124, 163, 36);

      .react-datepicker__current-month {
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;
      }

      .react-datepicker__day-name {
        color: #fff;
        font-weight: 600;
        text-transform: capitalize;
      }
    }

    .react-datepicker__month {
      .react-datepicker__day {
        color: rgb(40, 54, 70);
        font-weight: 600;
      }

      .react-datepicker__day--disabled {
        opacity: 0.4;
      }

      .react-datepicker__day:hover,
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--selected,
      .react-datepicker__day--in-range {
        background-color: rgb(124, 163, 36);
        color: #fff;
        border-radius: 0;
        font-weight: 600;
      }
    }
  }
}

.react-datepicker-wrapper {
  .react-datepicker__input-container input {
    padding: 0;
    border: 0;
    font-weight: 500;
    width: 100%;
  }
}
