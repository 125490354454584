.container {
  background: #2E4334;
  border-radius: 0 10px 10px 10px;
  padding: 20px;

  :global(.form-control) {
    border: 0;

    input {
      border: 0
    }
  }
}

.notice {
  color: #ffffff;

  a {
    color: currentColor;
    text-decoration: underline;
  }

}