@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.slider {
  display: flex;
  gap: 20px;
  padding-bottom: 16px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
  }

  @include media-breakpoint-up(md) {
    gap: 48px;
  }

  > * {
    scroll-snap-align: center;
  }
}

.footer {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

.controls {
  display: flex;
  gap: 10px;
}
