@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.wrapper {
  background: url('./background.jpg') no-repeat top center / cover;
  padding: 60px 0;

  color: #ffffff;
  line-height: normal;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.title {
  font-size: 70px;
  font-weight: 600;
  text-transform: uppercase;

  @include media-breakpoint-down(md) {
    font-size: 30px;
  }
}

.subtitle {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 10px;

  @include media-breakpoint-down(md) {
    font-size: 20px;
  }
}

.title,
.subtitle,
.description {
  text-align: center;
}

.link {
  color: #ffffff;

  &:hover,
  &:active {
    color: #ffffff;
    text-decoration: underline;
  }
}
