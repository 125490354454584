@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.banner {
  display: none;

  @include media-breakpoint-up(md) {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-image: inherit;

    display: flex;
    align-items: center;
    height: 500px;

    .header {
      margin-bottom: 0;
      text-align: center;
      font-size: 70px;
      font-weight: 600;
      color: #fff;
      text-transform: uppercase;
    }
  }
}
