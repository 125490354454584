.btn-success,
.btn-danger {
  color: #fff;
}
.btn.with-icon {
  display: inline-flex;
  align-items: center;
  margin-right: 6px;
}

.btn:focus,
.btn.focus {
  z-index: 1;
}

.fixed-button-wrapper {
  position: absolute;
  width: 100%;
  z-index: 100;

  .fixed-button-corner {
    left: 10px;
    bottom: 15px;
    position: fixed;
    z-index: 100;
  }

  .FixedButton {
    position: fixed;
    z-index: 100;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;

    a {
      white-space: normal;
    }

    @include media-breakpoint-down(xs) {
      max-width: 200px;
    }

    @include media-breakpoint-down(sm) {
      right: 10px;
      bottom: 10px;
      max-width: 200px;

      .topImg {
        display: none;
      }
    }

    @include media-breakpoint-between(lg, xl) {
      top: 20%;
      max-width: 100px;
    }

    @include media-breakpoint-up(xl) {
      top: 20%;
      max-width: 200px;
    }

    .topImg {
      width: 100%;
    }

    .bottomImg {
      width: 75%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      @include media-breakpoint-down(xs) {
        display: none;
      }

      @include media-breakpoint-between(md, xl) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      img {
        width: 100%;
      }
    }
  }
}
