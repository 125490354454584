.rate-item {
  border-top-width: 1px !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 3px;
  margin-bottom: 5px;
  padding: 10px 0 10px 15px;

  .rate-price {
    font-size: 1rem;
    margin-right: 5px;
  }

  .rack-price {
    font-size: 0.9rem;
    color: #888888;
    font-weight: 400;
    padding-left: 10px;
    padding-right: 5px;
    text-decoration: line-through;
  }

  .rate-discount {
    font-size: 0.9rem;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
  }

  .quantatity {
    font-size: 20px;
    color: #82a03c;
  }

  .rate-information {
    font-size: 0.8rem;
    color: #666666;
  }
}

.small-rating-text {
  font-size: 75%;
  margin-top: -8px;
}

.small-secure-payment-text {
  margin-top: 5px;
  font-weight: 600;
  font-size: 75%;
}

.free-benefit {
  font-weight: 500;
  color: #7ca324;
}

.third-party-info {
  border: 2px solid #f3604a;
  padding: 5px 5px 5px 20px;
  font-weight: 500;
  border-radius: 5px;
  margin-top: 20px;
}

.more-images-text {
  pointer-events: none;
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: inline-block;
  font-weight: 500;
  color: #ffffff;
  padding: 0px 8px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.188);
}

.teetime-currency {
  color: #888;
  font-size: 0.65rem;
}

.course-list-item {
  background-color: #f2f2f2;
  &:not(:last-child) {
    margin-bottom: 50px;
  }
}

.course-availability-nav {
  border-bottom: 2px solid rgb(240, 171, 41) !important;
  margin-bottom: 10px;
  cursor: pointer;

  p {
    margin-bottom: 0;
  }

  & .nav-link.active {
    border: 2px solid rgb(240, 171, 41);
    border-bottom: none;
    background-color: #f2f2f2;
    cursor: default;

    p {
      font-weight: bold;
    }
  }

  & .nav-link:not(.active):hover {
    border: none;
  }
}
