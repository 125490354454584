$primary-color: #84a13d;
$delimiter-color: #dedede;

$datepicker__background-color: transparent;
$datepicker__border-color: transparent;
$datepicker__highlighted-color: #3dcc4a;
$datepicker__muted-color: #ccc;
$datepicker__selected-color: $primary-color;
$datepicker__text-color: #333;
$datepicker__header-color: #333;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%);

$datepicker__border-radius: 0;
$datepicker__day-margin: 0;
$datepicker__font-size: 14px;
$datepicker__font-family: 'Montserrat', 'Open Sans', 'Helvetica Neue', helvetica, arial, sans-serif;
$datepicker__item-size: 40px;
$datepicker__margin: 0;
$datepicker__navigation-button-size: 32px;
$datepicker__triangle-size: 0;

@import 'react-datepicker/src/stylesheets/datepicker';

.react-datepicker {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  background: none;
  border: 0;
}

.react-datepicker__week {
  display: flex;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  &--keyboard-selected {
    background-color: lighten($datepicker__selected-color, 20%);
  }
}

.react-datepicker__month {
  border: 1px solid $delimiter-color;
}

.react-datepicker__day:empty {
  visibility: hidden;
}

.react-datepicker__day:not(:first-child) {
  border-left: 1px solid $delimiter-color;
}

.react-datepicker__week:not(:first-child) {
  border-top: 1px solid $delimiter-color;
}

.react-datepicker__navigation-icon {
  font-size: inherit;
}

.react-datepicker__navigation {
  top: 6px;
}

.react-datepicker__current-month {
  line-height: 1.65rem;
}