.tooltip {
  position: relative;

  :global(.tooltip) {
    visibility: hidden;
  }

  &:hover > :global(.tooltip) {
    opacity: 0.9;
    visibility: visible;
  }
}
