@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.tab {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  border-radius: 10px 10px 0 0;
  background-color: #8c978f;
  border-color: #8c978f;
  color: #ffffff;
  white-space: nowrap;

  &:hover,
  &.active {
    background-color: #2e4334;
    border-color: #2e4334;
  }

  @include media-breakpoint-down(md) {
    .label {
      display: none;
    }

    &.active {
      .label {
        display: unset;
      }
    }
  }
}
