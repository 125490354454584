@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';

.button {
  display: inline-flex;
  position: relative;

  &:not(.outline) {
    background: #f3f5f7;
    color: #7ca324;
  }

  &.primary {
    background-color: #84a13d;
    color: #fff;

    &.outline {
      background-color: transparent;
      color: #84a13d;

      &:hover {
        background-color: #84a13d;
        color: #fff;
      }
    }
  }

  &.tertiary {
    background-color: #cccccc;
    border-color: #ccc;
    color: #333;

    &:hover,
    &:active {
      background-color: darken(#ccc, 10%);
      border-color: darken(#ccc, 10%);
    }
  }

  &.rounded {
    border-radius: 50px;
    padding: $btn-padding-y;
  }

  &:hover {
    color: #000000;
  }
}

.body {
  display: inline-flex;
  align-items: center;
  gap: 10px;

  :global(.btn-sm) > & {
    gap: 4px
  }
}

.loader {
  visibility: hidden;
  opacity: 0;
}

.body,
.loader {
  transition: visibility 0.2s ease, opacity 0.2s ease;
}

.loading {
  .body {
    visibility: hidden;
    opacity: 0;
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: visible;
    opacity: 1;
  }
}
