@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.container {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: inherit;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  min-width: 624px;
  min-height: 400px;
  padding: 20px;
  border-radius: 12px;
  overflow: hidden;

  font-weight: 600;

  &:active,
  &:hover {
    .image {
      transform: scale(1.2);
    }
  }

  @include media-breakpoint-down(lg) {
    min-height: 300px;
    min-width: 300px;
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover;
  transition: transform ease 1.5s;
}

.title {
  color: #ffffff;
  font-size: 40px;
  z-index: 1;

  @include media-breakpoint-down(lg) {
    font-size: 20px;
  }
}
