//
// Color system
//

// stylelint-disable
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #222 !default; //#343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: () !default;
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #f3604a !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: rgb(124, 163, 36) !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

/* Ontee colors */
$ontee-orange: #ff8800 !default;
$ontee-red: #f3604a !default;
$ontee-white: #fff !default;
$ontee-cl0: #313131 !default;
$ontee-blue: #283646 !default;
$ontee-gray-light: #f7f7f8 !default;

$colors: () !default;
$colors: map-merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800,
    /* Ontee colors */ 'ontee-white': $ontee-white,
    'ontee-orange': $ontee-orange,
    'ontee-red': $ontee-red,
    'ontee-cl0': $ontee-cl0,
    'ontee-blue': $ontee-blue,
    'ontee-gray-light': $ontee-gray-light,
  ),
  $colors
);

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: #283646 !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    /* Ontee colors */ 'ontee-orange': $ontee-orange,
    'ontee-red': $ontee-red,
    'ontee-white': $ontee-white,
    'ontee-cl0': $ontee-cl0,
    'ontee-blue': $ontee-blue,
    'ontee-gray-light': $ontee-gray-light,
  ),
  $theme-colors
);

$tooltip-max-width: 300px;
$tooltip-padding-y: 0.5rem;
$tooltip-padding-x: 0.5rem;

$font-family-base: 'Montserrat', 'Open Sans', 'Helvetica Neue', Arial, sans-serif;

$modal-backdrop-bg: #2c2c36;
$modal-backdrop-opacity: 0.8;

$tooltip-bg: var(--tooltip-bg, $black);
