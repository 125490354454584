@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/dropdown';

.menu {
  @extend .dropdown-menu;
  min-width: 180px;
  position: static;

  font-size: 15px;

  @include media-breakpoint-down(md) {
    & & {
      border: 0;
      margin-left: 1.4em;
    }
  }
}

.show {
  @extend .show;
}

.item {
  @extend .dropdown-item;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  white-space: normal;
}

.caret {
  @include media-breakpoint-up(md) {
    @include caret(end);

    &::after {
      vertical-align: 0;
    }
  }
}
