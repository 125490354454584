@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

$radius: 12px;

.container {
  display: flex;
  min-width: 250px;
  flex-direction: column;
  align-items: stretch;
  flex: 1 0 0;
  gap: 20px;

  border-radius: $radius;

  font-size: 16px;
  line-height: normal;
  color: #222529;

  &:active,
  &:hover {
    color: #7ca324;

    .image {
      transform: scale(1.2);
    }
  }

  @include media-breakpoint-up(md) {
    min-width: 288px;
  }
}

.imageWrapper {
  overflow: hidden;
  border-radius: $radius;
}

.image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  transition: transform ease 1.5s;

  @include media-breakpoint-up(md) {
    min-width: 288px;
  }
}

.title {
  margin-bottom: 0;
  font-size: 26px;
  font-weight: bold;
}

.info {
  display: flex;
  gap: 20px;
  color: #222529;
}

.readingTime {
  white-space: nowrap;
}
