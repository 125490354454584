.navbar {
    .navbar-nav {
        .nav-link {
            text-transform: uppercase;
            font-size: 0.9rem;
            color: #000;

            &:hover {
                color: #000;
            }
        }
    }

    .dropdown-menu {
        min-width: 350px;
        z-index: 1030;
        font-size: 0.95rem;
    }
}

.navbar-collapse.collapse.show {
    max-height: 90vh;
    overflow-y: auto;
}

.navbar.navbar-light {
    border-bottom: 2px solid #7ca324;
}
