@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

$radius: 12px;

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 0 0;
  margin-bottom: 7px; // reserve space for box-shadow

  border-radius: $radius;
  background: #fff;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.1);

  font-size: 16px;
  font-weight: 600;

  &:active,
  &:hover {
    .image {
      transform: scale(1.2);
    }
  }
}

.responsive {
  min-width: 280px;

  @include media-breakpoint-up(md) {
    min-width: 400px;
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 16px;
}

.footer {
  margin-top: auto;
  padding: 8px 20px 20px;
  text-align: right;
}

.imageWrapper {
  overflow: hidden;
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
  position: relative;
}

.image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform ease 1.5s;
}

.title {
  margin-bottom: 0;
  color: #2e4334;
  font-size: 26px;
  font-weight: bold;
}

.subtitle {
  margin-bottom: 0;
  color: #7ca324;
  font-size: 20px;
  font-weight: bold;
}

.location {
  color: #5b5b5b;
  display: flex;
  align-items: center;
  gap: 8px;
}
