.slide-item {
    transition: opacity 1.9s ease-in-out;
    opacity: 1;

    img {
        @include media-breakpoint-up(xl) {
            width: 100%;
        }
    }
}

.slide-item.hide {
    opacity: 0;
    pointer-events: none;
}
